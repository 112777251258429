import * as types from './mutation_types';
import { colorLog } from '@helpers/utils.js';

export default {
  setAppReady({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_APP_READY, payload);
        resolve();
      } catch (e) {
        colorLog({
          label: 'Store error',
          message: `${e}`,
          themeName: 'blue',
        });
        reject(e);
      }
    });
  },
  setLoading({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_LOADING, payload);
        resolve();
      } catch (e) {
        colorLog({
          label: 'Store error',
          message: `${e}`,
          themeName: 'blue',
        });
        reject(e);
      }
    });
  },
  setSvgBrandLogo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_SVG_BRAND_LOGO, payload);
        resolve();
      } catch (e) {
        colorLog({
          label: 'Store error',
          message: `${e}`,
          themeName: 'blue',
        });
        reject(e);
      }
    });
  },
  setUnlockedView({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_UNLOCKED_VIEW, payload);
        resolve();
      } catch (e) {
        colorLog({
          label: 'Store error',
          message: `${e}`,
          themeName: 'blue',
        });
        reject(e);
      }
    });
  },
};
