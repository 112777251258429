export const hexToRgbValues = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  /*{
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  }*/
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}`
    : null;
};

try {
  const rootEl = document.getElementById('app-root');
  const brand = rootEl.dataset.currentBrand;
  const brands = JSON.parse(rootEl.dataset.brands);
  const currentBrandData = brands.find((b) => b.id === brand);
  const brandColor = currentBrandData.brandColor;
  const brandColorRGB = hexToRgbValues(brandColor);
  const textColorOnBrandColor = currentBrandData.ribbonForegroundColor;

  document.documentElement.style.setProperty('--brand-color', brandColor);
  document.documentElement.style.setProperty(
    '--brand-color-rgb',
    brandColorRGB
  );
  document.documentElement.style.setProperty(
    '--text-color-on-brand-color',
    textColorOnBrandColor
  );
} catch (e) {
  console.info('setting default colors');
  document.documentElement.style.setProperty('--brand-color', '#00A3E0');
  document.documentElement.style.setProperty(
    '--brand-color-rgb',
    hexToRgbValues('#00A3E0')
  );
  document.documentElement.style.setProperty(
    '--text-color-on-brand-color',
    '#FFF'
  );
}
