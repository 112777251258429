<template>
  <div class="FilterCheckboxGroupCmp">
    <div class="row g-2">
      <div
        v-for="item in options"
        :key="`checkbox_${name}_${item.slug}`"
        class="col-auto"
      >
        <filter-checkbox-cmp
          :name="name"
          :checked-value="item.slug"
          :value="value"
          :disabled="disabled"
          @input="$emit('input', $event)"
        >
          <template v-if="item.logo && item.logoSelected">
            <img
              :src="value.includes(item.slug) ? item.logoSelected : item.logo"
              alt=""
              width="75"
              height="50"
            />
          </template>
          <template v-else>
            {{ item.label }}
          </template>
        </filter-checkbox-cmp>
      </div>
    </div>
  </div>
</template>

<script>
import FilterCheckboxCmp from '@components/filter-checkbox-cmp';

export default {
  name: 'FilterCheckboxGroupCmp',
  components: { FilterCheckboxCmp },
  props: {
    value: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  mounted() {
    this.options.forEach((item) => {
      if (item.logo && item.logoSelected) {
        const image1 = new Image();
        image1.src = item.logo;
        const image2 = new Image();
        image2.src = item.logoSelected;
      }
    });
  },
};
</script>

<style scoped lang="scss"></style>
