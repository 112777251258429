import * as types from '../mutation_types';

export default {
  [types.CREATE_CONTEXT_DATA](state, value) {
    state.create_api = value.create_api;
    state.read_api = value.read_api;
    state.pdf_id = value.pdf_id;
    state.pdf_file = value.pdf_file;
    state.reference_width = value.reference_width;
    state.reference_height = value.reference_height;
    state.reference_sign_width = value.reference_sign_width;
    state.reference_sign_height = value.reference_sign_height;
  },
  [types.CREATE_PDF_CONFIG](state, value) {
    state.pdf_id = value.id;
    state.pdf_file = value.pdf_file;
    if (
      value.images[0].width &&
      value.images[0].height &&
      value.images[0].sign_width &&
      value.images[0].sign_height
    ) {
      state.reference_width = value.images[0].width;
      state.reference_height = value.images[0].height;
      state.reference_sign_width = value.images[0].sign_width;
      state.reference_sign_height = value.images[0].sign_height;
    }
    state.images = value.images;
    state.currentPage = value.images.length
      ? value.images[0].page_number
      : null;
    if (!state.keep) {
      state.signatures = [];
      state.variables_configuration = [];
    } else {
      /**
       * Se è stato scelto di mantenere le configurazioni
       * Verifico se nell'array signatures ci sono firme su numero pagina X che sul nuovo pdf caricato di numero pagine Y,
       * con Y < X non si vedrebbero più e le rimuovo poiche la loro esistenza causerebbe problemi su EsignAnyWhere
       */
      const missingSigns = state.signatures.filter(
        (e) => e.page_number > value.images.length
      );
      if (missingSigns.length > 0) {
        let missingSignsRemovedFeedback =
          'Le seguenti aree di firma sono state rimosse: ';
        missingSigns.forEach((missingSign) => {
          const index = state.signatures.findIndex(
            (e) => e.id === missingSign.id
          );
          if (index > -1) {
            missingSignsRemovedFeedback += `id ${missingSign.id} sulla pagina n. ${missingSign.page_number}, `;
            state.signatures.splice(index, 1);
          }
        });
        const feedback = missingSignsRemovedFeedback.slice(0, -2);
        state.missingSignRemovedFeedback = feedback;
      }
    }
  },
  [types.RESET_MISSING_SIGN_REMOVED_FEEDBACK](state) {
    state.missingSignRemovedFeedback = '';
  },
  [types.UPDATE_PDF_CONFIG](state, value) {
    state.pdf_id = value.pdf_id;
    state.pdf_file = value.pdf_file;
  },
  [types.READ_PDF_CONFIG](state, value) {
    if (
      value.images[0].width &&
      value.images[0].height &&
      value.images[0].sign_width &&
      value.images[0].sign_height
    ) {
      state.reference_width = value.images[0].width;
      state.reference_height = value.images[0].height;
      state.reference_sign_width = value.images[0].sign_width;
      state.reference_sign_height = value.images[0].sign_height;
    }
    state.images = value.images;
    state.currentPage = value.images.length
      ? value.images[0].page_number
      : null;
  },
  [types.DELETE_PDF_CONFIG](state, value) {
    state.pdf_id = null;
    state.pdf_file = null;
    state.reference_width = value.reference_width;
    state.reference_height = value.reference_height;
    state.reference_sign_width = value.reference_sign_width;
    state.reference_sign_height = value.reference_sign_height;
    state.images = [];
    state.currentPage = null;
    state.signatures = [];
    state.variables_configuration = [];
    state.keep = false;
  },
  [types.KEEP_PDF_CONFIG](state, value) {
    state.pdf_id = null;
    state.pdf_file = null;
    state.reference_width = value.reference_width;
    state.reference_height = value.reference_height;
    state.reference_sign_width = value.reference_sign_width;
    state.reference_sign_height = value.reference_sign_height;
    state.images = [];
    state.currentPage = null;
    state.keep = true;
  },
  [types.UPDATE_CURRENT_PAGE](state, value) {
    state.currentPage = value;
  },
  [types.CREATE_ALL_SIGNATURE](state, value) {
    value.signatures.forEach((e) => {
      const newX =
        (e.signature_position_x * value.new_work_area_width) /
        value.old_work_area_width;
      const newY =
        (e.signature_position_y * value.new_work_area_height) /
        value.old_work_area_height;
      const newW =
        (e.signature_width * value.new_work_area_width) /
        value.old_work_area_width;
      const newH =
        (e.signature_height * value.new_work_area_height) /
        value.old_work_area_height;

      state.signatures.push({
        ...e,
        signature_position_x: Math.round(newX),
        signature_position_y: Math.round(newY),
        signature_width: Math.round(newW),
        signature_height: Math.round(newH),
      });
    });
  },
  [types.UPDATE_ALL_SIGNATURE](state, value) {
    for (const index of state.signatures.keys()) {
      const newX =
        (state.signatures[index].signature_position_x *
          value.new_work_area_width) /
        value.old_work_area_width;
      const newY =
        (state.signatures[index].signature_position_y *
          value.new_work_area_height) /
        value.old_work_area_height;
      const newW =
        (state.signatures[index].signature_width * value.new_work_area_width) /
        value.old_work_area_width;
      const newH =
        (state.signatures[index].signature_height *
          value.new_work_area_height) /
        value.old_work_area_height;

      state.signatures.splice(index, 1, {
        ...state.signatures[index],
        signature_position_x: Math.round(newX),
        signature_position_y: Math.round(newY),
        signature_width: Math.round(newW),
        signature_height: Math.round(newH),
      });
    }
  },
  [types.CREATE_SIGNATURE](state, value) {
    state.signatures.push(value);
  },
  [types.UPDATE_SIGNATURE](state, value) {
    const index = state.signatures.findIndex((e) => e.id === value.id);
    if (index > -1) {
      if (value.type === 'drag') {
        state.signatures.splice(index, 1, {
          ...state.signatures[index],
          signature_position_x: Math.round(value.signature_position_x),
          signature_position_y: Math.round(value.signature_position_y),
        });
      } else if (value.type === 'resize') {
        state.signatures.splice(index, 1, {
          ...state.signatures[index],
          signature_width: Math.round(value.signature_width),
          signature_height: Math.round(value.signature_height),
        });
      }
    }
  },
  [types.DELETE_SIGNATURE](state, value) {
    const index = state.signatures.findIndex((e) => e.id === value);
    if (index > -1) {
      state.signatures.splice(index, 1);
    }
  },
  [types.CREATE_ALL_VARIABLE](state, value) {
    value.variables_configuration.forEach((e) => {
      const newX =
        (e.variable_position_x * value.new_work_area_width) /
        value.old_work_area_width;
      const newY =
        (e.variable_position_y * value.new_work_area_height) /
        value.old_work_area_height;

      state.variables_configuration.push({
        ...e,
        variable_position_x: Math.round(newX),
        variable_position_y: Math.round(newY),
      });
    });
  },
  [types.UPDATE_ALL_VARIABLE](state, value) {
    for (const index of state.variables_configuration.keys()) {
      const newX =
        (state.variables_configuration[index].variable_position_x *
          value.new_work_area_width) /
        value.old_work_area_width;
      const newY =
        (state.variables_configuration[index].variable_position_y *
          value.new_work_area_height) /
        value.old_work_area_height;

      state.variables_configuration.splice(index, 1, {
        ...state.variables_configuration[index],
        variable_position_x: Math.round(newX),
        variable_position_y: Math.round(newY),
      });
    }
  },
  [types.CREATE_VARIABLE](state, value) {
    state.variables_configuration.push(value);
  },
  [types.UPDATE_VARIABLE](state, value) {
    const index = state.variables_configuration.findIndex(
      (e) => e.id === value.id
    );
    if (index > -1) {
      if (value.type === 'drag') {
        state.variables_configuration.splice(index, 1, {
          ...state.variables_configuration[index],
          variable_position_x: Math.round(value.variable_position_x),
          variable_position_y: Math.round(value.variable_position_y),
        });
      } else if (value.type === 'update') {
        state.variables_configuration.splice(index, 1, {
          ...state.variables_configuration[index],
          variable_name: value.variable_name,
          fontsize: value.fontsize,
        });
      }
    }
  },
  [types.DELETE_VARIABLE](state, value) {
    const index = state.variables_configuration.findIndex(
      (e) => e.id === value
    );
    if (index > -1) {
      state.variables_configuration.splice(index, 1);
    }
  },
};
