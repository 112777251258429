/* Router dependencies */
import Vue from 'vue';
import VueRouter from 'vue-router';
import CarlineSelectorCmp from '@components/carline-selector-cmp';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: CarlineSelectorCmp,
  },
  {
    path: '*',
    redirect: { name: 'home' },
  },
];

const baseUrl = document
  .getElementById('app-root')
  .getAttribute('data-base-url-for-routing');

const router = new VueRouter({
  mode: 'history',
  base: baseUrl || '/',
  routes,
});

export default router;
