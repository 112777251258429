<template>
  <footer
    :class="[
      'FooterCmp',
      {
        branded: !!currentBrand,
      },
    ]"
    :style="componentStyles"
  >
    <div class="container">
      <div class="row">
        <div class="col">
          <nav class="footer-nav">
            <ul>
              <li>
                <button @click.prevent="showCookiebot">Cookie Policy</button>
              </li>
              <li
                v-for="footerData in footerConfigurationData"
                :key="footerData.id"
              >
                <a :href="footerData.link" target="_blank">
                  {{ footerData.label }}
                </a>
              </li>
              <li v-if="$context.faqLabel">
                <a :href="$context.faqUrl" target="_blank">
                  {{ $context.faqLabel }}
                </a>
              </li>
              <li v-if="$context.logoutUrl">
                <a :href="$context.logoutUrl">Logout</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterCmp',
  // props: {
  //   dataBrand: {
  //     type: String,
  //     default: 'stellantis',
  //   },
  // },
  computed: {
    componentStyles() {
      return {
        backgroundColor:
          this.currentBrandData?.footerBackgroundColor || '#243782',
        color: this.currentBrandData?.footerForegroundColor || '#fff',
      };
    },
    currentBrand() {
      return this.$context.currentBrand;
    },
    brands() {
      return this.$context.brands;
    },
    currentBrandData() {
      return this.$context.brands.find((b) => b.id === this.currentBrand);
    },
    footerConfigurationData() {
      return this.$context.footerConfiguration.footer_links.length > 0
        ? this.$context.footerConfiguration.footer_links
        : [];
    },
  },
  methods: {
    showCookiebot() {
      (() => {
        let doCheck = () => {
          if (window.Cookiebot) {
            window.Cookiebot.show();
          } else {
            setTimeout(doCheck, 50);
          }
        };
        doCheck();
      })();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.FooterCmp {
  display: flex;
  align-items: center;
  width: 100%;
  @include responsive-properties(('height'), $footer-height);

  .footer-nav {
    ul {
      display: flex;
      justify-content: center;
      width: 100%;

      li {
        padding: 0 10px;

        a,
        button {
          font-size: 16px;
          font-weight: $fh-light;
          color: inherit;
          &:hover {
            text-decoration: underline;
          }

          @include media-breakpoint-up(md) {
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
