/* Mutations, actions and getters */
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    currentStep: 1,
    disabledFrom: 2, // it's always currentStep + 1, added only for clarity, doesn't mutate, usefull for detect the starting position
    otp: false,
    provincesList: [],
    countriesList: [],
    renting_session_status: {
      step_1_pre_contract_done: false,
      step_2_customer_data_done: false,
      step_3_income_done: false,
      step_4_car_data_done: false,
      step_5_lease_completed_done: false,
    },
    step_1_pre_contract: {
      pre_contract_file: '',
      pre_contract_file_accepted: false,
      privacy_policy: false,
      marketing_privacy_policy: false,
      third_party_privacy_policy: false,
    },
    step_2_customer_data: {
      first_name: '',
      last_name: '',
      gender: 'man',
      telephone: '',
      email: '',
      birth_date: null,
      birth_city: '',
      residence_address_street: '',
      residence_address_number: '',
      residence_zipcode: '',
      residence_city: '',
      residence_province: '',
      domicile_address_street: '',
      domicile_address_number: '',
      domicile_zipcode: '',
      domicile_city: '',
      domicile_province: '',
      civil_state: 'celibe/nubile',
      family_members_count: '',
      home_info: 'proprietario',
      nationality: 'IT',
      politically_exposed: false,
      document_identity_type: 'patente',
      document_number: '',
      document_issued_by: 'dtt/motorizzazione-civile',
      document_city_release: '',
      document_date: null,
      document_expiration_date: null,
      residency_permit_file: null,
      document_identity_image_front_file: null,
      document_identity_image_back_file: null,
      tax_code: '',
      health_card_image_front_file: null,
      health_card_image_back_file: null,
      residency_permit_s3_path: '',
      document_identity_image_front_s3_path: '',
      document_identity_image_back_s3_path: '',
      health_card_image_front_s3_path: '',
      health_card_image_back_s3_path: '',
    },
    step_3_income: {
      contract_type: 'contratto-tempo-indeterminato',
      sector_employee: 'privato',
      company_name: '',
      vat_number: '',
      company_phone: '',
      company_address_street: '',
      company_address_number: '',
      company_zipcode: '',
      company_city: '',
      company_province: '',
      document_paycheck_file: null,
      document_other_income_file: null,
      document_contract_employment_file: null,
      document_iban_file: null,
      iban: '',
      start_date_activity: null,
      document_receipt_unique_model_file: null,
      document_paycheck_s3_path: '',
      document_other_income_s3_path: '',
      document_contract_employment_s3_path: '',
      document_iban_s3_path: '',
      document_receipt_unique_model_s3_path: '',
      employee_contract_type: 'peugeot_citroen_ds_opel_employee',
      is_member_of_employee_family: null,
      employee_badge_front_file: '',
      employee_badge_back_file: '',
      employee_badge_front_file_s3_path: '',
      employee_badge_back_file_s3_path: '',
    },
    step_4_car_data: {
      license_plate: '',
      has_not_license_plate: true,
    },
    step_5_lease_completed: {
      is_contract_file_accepted: false,
      contract_stipulation_file: '',
    },
  },
  actions,
  mutations,
  getters,
};
