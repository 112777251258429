export const allowUrls = [
  //  /https?:\/\/((cdn|www)\.)?example\.com/
];

export const ignoreErrors = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // avoid PetalBot errors in scraping website
  'sendBeacon',
  // avoid errors on restart before chunk are updated
  'Loading chunk',
];

export const beforeSend = (event) => {
  const badCrawlersList = [
    'MJ12Bot',
    'PetalBot',
    'AspiegelBot',
    'AhrefsBot',
    'SEMrushBot',
    'DotBot',
    'MauiBot',
  ];
  const ua = event?.request?.headers['User-Agent'];

  const uaIsABadCrawler = (ua, badCrawlersList) => {
    return badCrawlersList.reduce((acc, curr) => {
      if (!acc && ua.toLowerCase().includes(curr.toLowerCase())) {
        return true;
      } else {
        return acc;
      }
    }, false);
  };

  if (ua && uaIsABadCrawler(ua, badCrawlersList)) {
    return null;
  } else {
    return event;
  }
};

export const tracesSampler = (samplingContext) => {
  // if ("...") {
  //   // Drop this transaction, by setting its sample rate to 0%
  //   return 0;
  // } else {
  //   // Default sample rate for all others (replaces tracesSampleRate)
  //   return 0.1;
  // }
};

export const denyUrls = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];
