var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{class:[
    'HeaderCmp',
    {
      branded: !!_vm.currentBrand && _vm.svgLogoId !== 'logo-stellantis',
      isVisorVariant: _vm.isVisorVariant,
    },
  ],style:(_vm.componentStyles)},[(_vm.isVisorVariant)?_c('img',{staticClass:"visorImage",attrs:{"src":_vm.headerImage,"alt":`${_vm.$context.cmsEntry.homepage_header_title} - ${_vm.$context.cmsEntry.homepage_header_subtitle}`}}):_vm._e(),(_vm.isVisorVariant)?_c('cta-button-cmp',{staticClass:"scrollToInfoCta psa-button big-button py-5",attrs:{"data-tag":"a","data-url":`#${_vm.anchorHref}`}},[_vm._v(" Scopri di più ")]):_vm._e(),_c('div',{staticClass:"headerWrapper"},[_c('div',{class:[
        {
          'container': !_vm.isVisorVariant,
          'container-fluid': _vm.isVisorVariant,
        },
      ]},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.$currentStep === 'xs' || _vm.$currentStep === 'sm')?_c('i',{class:[
              'brandLine',
              {
                visible: _vm.hasBrandLine && !_vm.hideBrandAndLine,
              },
            ]}):_vm._e(),(!_vm.hideBrandAndLine)?_c('a',{staticClass:"logo",attrs:{"href":_vm.homepageUrl}},[(_vm.svgLogoId !== 'logo-stellantis')?_c('svg',[_c('use',{attrs:{"href":`#${_vm.svgLogoId}`,"fill":"currentColor"}})]):_c('img',{attrs:{"src":require(`../../img/logo_sr_big_alt.png`),"alt":"logo-stellantis"}})]):_vm._e(),_c('i',{class:[
              'brandLine',
              { visible: _vm.hasBrandLine && !_vm.hideBrandAndLine },
            ]})])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }