<template>
  <transition name="app-loader">
    <div v-if="loading" class="AppLoaderCmp">
      <loader-cmp />
    </div>
  </transition>
</template>

<script>
import LoaderCmp from '@components/loader-cmp';

export default {
  name: 'AppLoaderCmp',
  components: {
    LoaderCmp,
  },
  computed: {
    loading() {
      return this.$store.state.loading.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.AppLoaderCmp {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba($color-black, 0.2);
  z-index: map_get($z, top);
}

.app-loader-enter-active,
.app-loader-leave-active {
  transition: opacity 0.5s;
}

.app-loader-enter,
.app-loader-leave-to {
  opacity: 0;
}
</style>
