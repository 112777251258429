/* Store initialization */
export const SET_APP_READY = 'SET_APP_READY';
export const SET_LOADING = 'SET_LOADING';
export const SET_UNLOCKED_VIEW = 'SET_UNLOCKED_VIEW';
export const SET_SVG_BRAND_LOGO = 'SET_SVG_BRAND_LOGO';


/* Quiz initialization */
export const SET_QUIZ_INIT_DATA = 'SET_QUIZ_INIT_DATA';
export const SET_ERRORS = 'SET_ERRORS';
export const SAVE_RESPONSE = 'SAVE_RESPONSE';
export const IS_IN_EDIT_MODE = 'IS_IN_EDIT_MODE';

/* Products initialization */
export const SET_PRODUCTS_INIT_DATA = 'SET_PRODUCTS_INIT_DATA';
export const RESET_PRODUCTS = 'RESET_PRODUCTS';
export const SET_PRODUCT_DETAIL = 'SET_PRODUCT_DETAIL';
export const RESET_PRODUCT_DETAIL = 'RESET_PRODUCT_DETAIL';
export const SET_PRODUCTS_API_ERRORS = 'SET_PRODUCTS_API_ERRORS';

/* PDF config */
export const CREATE_CONTEXT_DATA = 'CREATE_CONTEXT_DATA';
export const CREATE_PDF_CONFIG = 'CREATE_PDF_CONFIG';
export const UPDATE_PDF_CONFIG = 'UPDATE_PDF_CONFIG';
export const READ_PDF_CONFIG = 'READ_PDF_CONFIG';
export const DELETE_PDF_CONFIG = 'DELETE_PDF_CONFIG';
export const KEEP_PDF_CONFIG = 'KEEP_PDF_CONFIG';
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE';
export const CREATE_ALL_SIGNATURE = 'CREATE_ALL_SIGNATURE';
export const UPDATE_ALL_SIGNATURE = 'UPDATE_ALL_SIGNATURE';
export const CREATE_SIGNATURE = 'CREATE_SIGNATURE';
export const UPDATE_SIGNATURE = 'UPDATE_SIGNATURE';
export const DELETE_SIGNATURE = 'DELETE_SIGNATURE';
export const CREATE_ALL_VARIABLE = 'CREATE_ALL_VARIABLE';
export const UPDATE_ALL_VARIABLE = 'UPDATE_ALL_VARIABLE';
export const CREATE_VARIABLE = 'CREATE_VARIABLE';
export const UPDATE_VARIABLE = 'UPDATE_VARIABLE';
export const DELETE_VARIABLE = 'DELETE_VARIABLE';
export const RESET_MISSING_SIGN_REMOVED_FEEDBACK =
  'RESET_MISSING_SIGN_REMOVED_FEEDBACK';

/* Wizard */
export const UPDATE_WIZARD_DATA = 'UPDATE_WIZARD_DATA';
export const UPDATE_CURRENT_STEP = 'UPDATE_CURRENT_STEP';
export const UPDATE_DISABLED_FROM = 'UPDATE_DISABLED_FROM';
export const UPDATE_OTP = 'UPDATE_OTP';
export const UPDATE_PROVINCES = 'UPDATE_PROVINCES';
export const UPDATE_COUNTRIES = 'UPDATE_COUNTRIES';

export const UPDATE_STEP_DATA = 'UPDATE_STEP_DATA';
