<template lang="html">
  <accordion-cmp class="AccordionExtended" v-bind="$attrs">
    <template #header="{ index, item }">
      <div class="d-flex">
        <img
          v-if="item.image"
          :src="item.image"
          :alt="item.title"
          class="icon"
        />
        <h4 class="title flex-grow-1">{{ item.title }}</h4>
        <div class="arrow">
          <svg
            aria-hidden="true"
            focusable="false"
            class="chevron"
            width="12"
            height="7"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            role="img"
            viewBox="0 0 12 7"
            :aria-labelledby="`titleAccordionChevron_${index} contentAccordionChevron_${index}`"
            fill="currentColor"
          >
            <title :id="`titleAccordionChevron_${index}`">Chevron icon</title>
            <desc :id="`contentAccordionChevron_${index}`">
              An icon that shows if accordion is open
            </desc>
            <path
              d="M7.412,9.5,5.93,10.887l6,5.613,6-5.613L16.447,9.5,11.93,13.726Z"
              transform="translate(-5.93 -9.5)"
            />
          </svg>
        </div>
      </div>
    </template>
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </accordion-cmp>
</template>

<script>
import AccordionCmp from '@frankhoodbs/lib/src/components/accordion-cmp';
import { colorLog } from '@helpers/utils.js';

export default {
  name: 'AccordionExtended',
  components: {
    AccordionCmp: {
      extends: AccordionCmp,
      // methods: {
      //   toggle(index) {
      //     AccordionCmp.methods.toggle.call(this, index); // call parent method
      //     colorLog({
      //       label: 'AccordionExtended',
      //       message: `toggle override ${index}`,
      //       themeName: 'vue',
      //     });
      //   },
      //   afterTransition(index) {
      //     colorLog({
      //       label: 'AccordionExtended',
      //       message: `afterTransition ${index}`,
      //       themeName: 'vue',
      //     });
      //   },
      // },
    },
  },
};
</script>

<style lang="scss" scoped>
.AccordionExtended {
  // override css vars
  --accordion-header-link-color: #626262;
  --accordion-header-link-color-hover: var(--brand-color);

  .icon {
    max-width: 50px;
    max-height: 50px;
    margin: 0 20px 0 0;
  }

  .title {
    word-break: break-word;
  }

  ::v-deep {
    --accordion-title-font-size: 1.22rem;
    .item {
      border: 1px solid #dedede;
      border-bottom: 0;
      &:last-child {
        border: 1px solid #dedede;
      }
    }

    .header-link {
      padding: var(--accordion-header-link-py, 1rem)
        var(--accordion-header-link-py, 1rem);
    }
    .content {
      font-size: 0.8rem;
      line-height: 1.5;
      padding-left: var(--accordion-header-link-py, 1rem);
      padding-right: var(--accordion-header-link-py, 1rem);
      p {
        margin: 0;
      }
    }
  }
  &.no-borders {
    ::v-deep {
      --accordion-title-font-size: 1.2rem;
      @include media-breakpoint-up(md) {
        --accordion-title-font-size: 1.8rem;
      }

      .title {
        font-weight: 500;
      }
      .item {
        border: 0;
        border-bottom: 1px solid #dedede;
      }
      .content {
        font-size: 0.8rem;
        @include media-breakpoint-up(md) {
          font-size: 1.05rem;
        }
      }
    }
  }
}

.header {
  font-weight: bold;
  margin: 0;
}
</style>
