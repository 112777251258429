<template>
  <div class="AdditionalDetails">
    <img class="wysiwygImage" :src="footerImage" alt="" />
    <div
      v-if="$context.cmsEntry.header_modal_text"
      id="footerInfoNotes"
      class="pt-8"
    >
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="wysiwyg" v-html="$context.cmsEntry.header_modal_text" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdditionalDetails',
  computed: {
    footerImage() {
      switch (this.$currentStep) {
        case 'md':
        case 'lg':
        case 'xl':
          return this.$context.cmsEntry.footer_image_l;
        // || 'https://via.placeholder.com/1920x200.png?text=LG' //;
        default:
          //sm
          return this.$context.cmsEntry.footer_image_mobile;
        // || 'https://via.placeholder.com/828x400.png?text=SM' //;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.AdditionalDetails {
  .wysiwygImage {
    max-width: 100%;
    display: block;
    margin: 15px auto;
  }
}
</style>
