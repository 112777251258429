<template>
  <div class="MaintenancePage">
    <header-cmp :data-variant="$context.cmsEntry.homepage_variant" />
    <main class="main-content">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="wysiwyg py-15">
              <slot name="wall-of-text"></slot>
            </div>
          </div>
        </div>
      </div>
    </main>
    <footer-cmp />
    <app-loader-cmp />
    <svg-icons-cmp />
  </div>
</template>

<script>
import AppLoaderCmp from '@components/app-loader-cmp';
import FooterCmp from '@components/footer-cmp';
import HeaderCmp from '@components/header-cmp';
import SvgIconsCmp from '@components/svg-icons-cmp';
import CarlineSelectorCmp from '@components/carline-selector-cmp';
import AdditionalDetails from '@components/additional-details';
import PageAccordions from '@components/page-accordions';

export default {
  name: 'MaintenancePage',
  components: {
    AppLoaderCmp,
    FooterCmp,
    HeaderCmp,
    SvgIconsCmp,
  },
  mounted() {
    this.$store.dispatch('setLoading', false);
    this.$store.dispatch('setAppReady', true);
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.MaintenancePage {
  .main-content {
    min-height: calc(
      100vh - #{fetch($header-height, xs)} - #{fetch($footer-height, xs)}
    );

    @include media-breakpoint-up(md) {
      min-height: calc(
        100vh - #{fetch($header-height, md)} - #{fetch($footer-height, md)}
      );
    }

    @include media-breakpoint-up(lg) {
      min-height: calc(
        100vh - #{fetch($header-height, lg)} - #{fetch($footer-height, lg)}
      );
    }
  }
  ::v-deep .cta-refresh-button {
    width: 100%;
    background: #00a3e0;
    padding: 20px 0;
    border: 0;
    font-size: 13px;
    font-weight: 700;
    color: white !important;
    display: block;
    width: 100%;
    text-align: center;
    max-width: 300px;
    transition: background 0.2s ease-out;
    &:hover {
      background: #0083b3;
    }
  }
}
</style>
