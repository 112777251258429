<template>
  <div class="FilterRangeCmp">
    <vue-slider
      :value="value"
      :min="min"
      :max="max"
      :min-range="minRange"
      :interval="interval"
      tooltip="none"
      :tooltip-placement="['right', 'left']"
      :tooltip-formatter="formatter"
      :disabled="disabled"
      @change="onChange"
      @drag-end="$emit('drag-end')"
    />
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';

export default {
  name: 'FilterRangeCmp',
  components: {
    VueSlider,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    minRange: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 100,
    },
    interval: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  methods: {
    onChange(value) {
      this.$emit('input', value);
    },
    formatter(val) {
      return `${val} €`;
    },
  },
};
</script>

<style lang="scss">
/* component style */
.vue-slider-disabled .vue-slider-process {
  background-color: $color-dark-grey;
}
.vue-slider-disabled .vue-slider-dot-handle {
  border-color: $color-dark-grey;
}
.vue-slider-disabled .vue-slider-mark-step-active {
  box-shadow: 0 0 0 2px $color-dark-grey;
}

/* rail style */
.vue-slider-rail {
  background-color: $color-grey;
  border-radius: 15px;
  transition: background-color 0.3s;
}

/* process style */
.vue-slider-process {
  background-color: $color-secondary;
  border-radius: 15px;
  transition: background-color 0.3s;
}

/* dot style */
.vue-slider-dot {
  outline: none !important;
}

.vue-slider-dot-handle {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: $color-white;
  border: 2px solid $color-secondary;
  box-sizing: border-box;
  transition: box-shadow 0.3s, border-color 0.3s;
}

.vue-slider-dot-handle-focus {
  box-shadow: 0 0 0 5px rgba(54, 171, 255, 0.2);
}

.vue-slider-dot-handle:hover {
  border-color: $color-primary;
}

.vue-slider-dot-handle-disabled {
  cursor: not-allowed;
  border-color: $color-dark-grey !important;
}
</style>
