<template>
  <div class="LoaderCmp">
    <svg v-if="dataType === 'circular'" class="circular">
      <circle
        class="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
    <template v-else-if="dataType === 'dots'">
      <div class="dots">
        <div class="dot" />
        <div class="dot" />
        <div class="dot" />
        <div class="dot" />
        <div class="dot" />
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="LoaderCmp__goo">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="12"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
              result="LoaderCmp__goo"
            />
            <feBlend in="SourceGraphic" in2="LoaderCmp__goo" />
          </filter>
        </defs>
      </svg>
    </template>
  </div>
</template>

<script>
export default {
  name: 'LoaderCmp',
  props: {
    dataType: {
      type: String,
      default: 'circular',
      validator: function (value) {
        // The value must match one of these strings
        return ['circular', 'dots'].indexOf(value) !== -1;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.LoaderCmp {
  display: inline-block;
  vertical-align: top;
  will-change: transform;

  /* Circular theme */
  .circular {
    position: relative;
    width: 50px;
    height: 50px;
    animation: rotate 2s linear infinite;

    .path {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
      stroke-linecap: round;
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
    @keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
      }
    }
    @keyframes color {
      100%,
      0% {
        stroke: $color-primary;
      }
      40% {
        stroke: $color-secondary;
      }
      66% {
        stroke: $color-tertiary;
      }
      80%,
      90% {
        stroke: $color-primary;
      }
    }
  }

  /* Dots theme */
  .dots {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    filter: url(#LoaderCmp__goo);
  }

  .dot {
    $dot-size: 35px;
    $circle-size: 70px;
    $animation-time: 4s;

    $color-yellow: #fbd301;
    $color-red: #ff3270;
    $color-blue: #208bf1;
    $color-green: #afe102;

    $colors: ($color-red, $color-blue, $color-green, $color-yellow);

    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;

    &::before {
      content: '';
      width: $dot-size;
      height: $dot-size;
      border-radius: 50px;
      background: $color-yellow;
      position: absolute;
      left: 50%;
      transform: translateY(0) rotate(0deg);
      margin-left: -($dot-size/2);
      margin-top: -($dot-size/2);
    }

    @keyframes dot-move {
      0% {
        transform: translateY(0);
      }
      18%,
      22% {
        transform: translateY(-($circle-size));
      }
      40%,
      100% {
        transform: translateY(0);
      }
    }
    @keyframes dot-colors {
      @for $i from 0 to 4 {
        #{$i*25%} {
          background-color: nth($colors, ($i + 3) %4 + 1);
        }
      }
      100% {
        background-color: nth($colors, 4);
      }
    }

    &:nth-child(5)::before {
      z-index: map_get($z, nav);
      width: $dot-size * 1.3;
      height: $dot-size * 1.3;
      margin-left: -($dot-size * 0.65);
      margin-top: -($dot-size * 0.65);
      animation: dot-colors $animation-time ease infinite;
    }

    @for $i from 0 to 4 {
      @keyframes dot-rotate-#{$i + 1} {
        0% {
          transform: rotate(#{($i + 1) * 270deg - 375deg});
        }
        100% {
          transform: rotate(#{($i + 1) * 270deg + 0deg});
        }
      }

      &:nth-child(#{$i + 1}) {
        animation: dot-rotate-#{$i +
          1}
          $animation-time
          #{$i *
          $animation-time /
          4}
          linear
          infinite;

        &::before {
          background-color: nth($colors, $i + 1);
          animation: dot-move
            $animation-time
            #{$i *
            $animation-time/4}
            ease
            infinite;
        }
      }
    }
  }
}
</style>
