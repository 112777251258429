var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BootstrapModalCmpNew"},[_c('transition',{attrs:{"name":"modal-backdrop"}},[(_vm.show)?_c('div',{staticClass:"modal-backdrop"}):_vm._e()]),_c('transition',{attrs:{"name":"modal-dialog"}},[(_vm.show)?_c('div',{staticClass:"modal-dialog",style:({ position: _vm.dataPosition }),on:{"click":function($event){if($event.target !== $event.currentTarget)return null;$event.stopPropagation();_vm.dataDismissible ? _vm.toggleModal(false) : null}}},[_c('div',{class:[
          'modal-content',
          {
            'container': _vm.dataBootstrapContainer,
            'container-fluid': !_vm.dataBootstrapContainer,
          },
        ],style:(_vm.dataStyle)},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col modal-header"},[_c('span',{staticClass:"modal-title"},[_vm._t("title")],2),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleModal(false)}}},[(_vm.hasCloseSlot)?_c('span',[_vm._t("close")],2):_vm._e(),_c('svg',{attrs:{"width":"24","height":"24"}},[_c('use',{attrs:{"href":"#icon-close","fill":"currentColor"}})])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col modal-body"},[_vm._t("content")],2)]),_c('transition',{attrs:{"name":"loader-wrapper"}},[(_vm.dataLoading)?_c('div',{staticClass:"loader-wrapper"},[_c('loader-cmp')],1):_vm._e()])],1)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }