import axios from 'axios';
import { getCookie } from '@/helpers/cookies_helpers';

axios.interceptors.request.use((config) => {
  const token = getCookie('csrftoken');
  if (token) {
    config.headers['X-CSRFToken'] = token;
  }
  return config;
});

axios.interceptors.response.use(
  (successResponse) => {
    return successResponse;
  },
  (error) => {
    const properties = Object.getOwnPropertyNames(error);
    const hasResponse = properties.includes('response');
    if (hasResponse) {
      if (error.response.status === 403) {
        try {
          if (
            error.response.data &&
            !('fail_silently' in error.response.data)
          ) {
            alert(error.response.data.detail);
          }
        } catch (e) {
          alert(
            `Non hai i permessi per effettuare questa operazione\r\nProva a ricaricare la pagina.`
          );
        }
      }
    }

    return Promise.reject(error);
  }
);
