<template>
  <div class="BootstrapModalCmpNew">
    <transition name="modal-backdrop">
      <div v-if="show" class="modal-backdrop" />
    </transition>
    <transition name="modal-dialog">
      <div
        v-if="show"
        class="modal-dialog"
        :style="{ position: dataPosition }"
        @click.self.stop="dataDismissible ? toggleModal(false) : null"
      >
        <div
          :class="[
            'modal-content',
            {
              'container': dataBootstrapContainer,
              'container-fluid': !dataBootstrapContainer,
            },
          ]"
          :style="dataStyle"
        >
          <div class="row">
            <div class="col modal-header">
              <span class="modal-title">
                <slot name="title" />
              </span>
              <button
                type="button"
                class="close"
                aria-label="Close"
                @click.stop="toggleModal(false)"
              >
                <span v-if="hasCloseSlot">
                  <slot name="close" />
                </span>
                <svg width="24" height="24">
                  <use href="#icon-close" fill="currentColor" />
                </svg>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col modal-body">
              <slot name="content" />
            </div>
          </div>
          <transition name="loader-wrapper">
            <div v-if="dataLoading" class="loader-wrapper">
              <loader-cmp />
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import LoaderCmp from './loader-cmp';
import { EventBus } from '@helpers/event-bus';

Vue.use(VueScrollTo);

export default {
  name: 'BootstrapModalCmpNew',
  components: {
    LoaderCmp,
  },
  props: {
    dataScrollTop: {
      type: Boolean,
      default: true,
    },
    dataDismissible: {
      type: Boolean,
      default: true,
    },
    dataLoading: {
      type: Boolean,
      default: false,
    },
    dataPosition: {
      type: String,
      default: 'fixed',
    },
    dataBootstrapContainer: {
      type: Boolean,
      default: false,
    },
    dataStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    hasCloseSlot() {
      return !!(this.$slots['close'] || [])[0];
    },
  },
  methods: {
    toggleModal(status) {
      if (status) {
        const scrollBarWidth = window.innerWidth - document.body.offsetWidth;

        if (this.dataScrollTop) {
          this.$scrollTo('body', 500, {
            cancelable: false,
            onDone: () => {
              this.$parent.$el.style.marginRight = scrollBarWidth
                ? `${scrollBarWidth}px`
                : '';
              document.body.classList.add('modal-open');
            },
          });
        } else {
          this.$parent.$el.style.marginRight = scrollBarWidth
            ? `${scrollBarWidth}px`
            : '';
          document.body.classList.add('modal-open');
        }
      } else {
        this.$parent.$el.style.marginRight = '';
        document.body.classList.remove('modal-open');
      }
      this.show = status;
      EventBus.$emit('toggle-modal', status);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_form-variables.scss';

.BootstrapModalCmpNew {
  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-black;
    opacity: 0.33;
    z-index: map_get($z, overlay);

    &.modal-backdrop-enter-active {
      transition: opacity 300ms ease-in;
    }

    &.modal-backdrop-leave-active {
      transition: opacity 600ms ease-in;
      transition-delay: 300ms;
    }

    &.modal-backdrop-enter,
    &.modal-backdrop-leave-to {
      opacity: 0;
    }
  }

  .modal-dialog {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    opacity: 1;
    z-index: map_get($z, modal);
    overflow-y: auto;
    overflow-x: hidden;

    &.modal-dialog-enter-active {
      transition: opacity 400ms ease-in;
      transition-delay: 300ms;
    }

    &.modal-dialog-leave-active {
      transition: opacity 300ms ease-in;
    }

    &.modal-dialog-enter,
    &.modal-dialog-leave-to {
      opacity: 0;
    }

    .modal-content {
      position: relative;
      background: $color-white;
      border-radius: 5px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.33);
      overflow: hidden;
      width: calc(100% - var(--fh-gutter-x, #{$container-padding-x}) * 2);
      min-height: calc(100% - var(--fh-gutter-x, #{$container-padding-x}) * 2);
      margin: calc(var(--fh-gutter-x, #{$container-padding-x}));
      padding: 0 $grid-gutter-width / 2;

      &.container {
        @each $step, $val in $container-max-widths {
          @include media-breakpoint-up($step) {
            max-width: calc(
              #{$val} - var(--fh-gutter-x, #{$container-padding-x}) * 2
            );
          }
        }
      }

      .modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 44px;
        background: $color-primary;
        color: $color-white;

        .modal-title {
          padding: 0 var(--fh-gutter-x, #{$container-padding-x}) 0 0;
        }

        .close {
          display: flex;
          align-items: center;
          justify-content: center;
          background: transparent;
          border: none;
          padding: 0;
          font-size: 13px;
          color: $color-white;

          span {
            padding: 0 5px 0 0;
          }

          svg {
            height: 30px;
          }
        }
      }

      .modal-body {
        padding-top: $grid-gutter-width / 2;
        padding-bottom: $grid-gutter-width / 2;
      }

      .loader-wrapper {
        position: absolute;
        top: 44px; // header height
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-white;
        opacity: 1;

        &.loader-wrapper-enter-active {
          transition: opacity 300ms ease-in;
        }

        &.loader-wrapper-leave-active {
          transition: opacity 600ms ease-in;
          transition-delay: 300ms;
        }

        &.loader-wrapper-enter,
        &.loader-wrapper-leave-to {
          opacity: 0;
        }
      }
    }
  }

  &.toast {
    .modal-dialog {
      .modal-content {
        min-height: auto;

        @include media-breakpoint-up(sm) {
          margin-right: auto;
          margin-left: auto;
        }

        &.container-fluid {
          @include media-breakpoint-up(sm) {
            width: 75vw;
          }

          @include media-breakpoint-up(md) {
            width: 50vw;
          }

          @include media-breakpoint-up(lg) {
            width: 33vw;
          }

          @include media-breakpoint-up(xl) {
            width: 25vw;
          }
        }
      }
    }

    &.error-toast {
      .modal-dialog {
        ::v-deep .modal-content {
          .modal-header {
            background: $form-color-error;
          }

          .modal-body {
            .error {
              font: $form-error-font;
              color: $form-color-error;
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
body {
  &.modal-open {
    overflow-y: hidden;
  }
}
</style>
