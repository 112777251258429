<template>
  <div class="HeadingCmp">
    <component :is="dataTag" class="heading-tag">
      <slot></slot>
    </component>
    <div v-if="hasSubtitle" class="subtitle mt-2 mt-lg-3">
      <slot name="subtitle"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeadingCmp',
  props: {
    dataTag: {
      type: String,
      default: 'h2',
    },
  },
  computed: {
    hasSubtitle() {
      return !!(this.$slots['subtitle'] || [])[0];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.HeadingCmp {
  text-align: center;
  mark {
    color: var(--brand-color);
  }

  a {
    color: var(--brand-color);
  }

  .heading-tag {
    font-size: 16px;
    font-weight: $fh-bold;

    @include media-breakpoint-up(sm) {
      font-size: 20px;
    }

    @include media-breakpoint-up(md) {
      font-size: 33px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 40px;
    }
  }

  .subtitle {
    font-size: 16px;
    font-weight: $fh-light;

    @include media-breakpoint-up(sm) {
      font-size: 20px;
    }

    @include media-breakpoint-up(md) {
      font-size: 26px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 32px;
    }
  }

  &.underlined {
    text-align: left;

    .heading-tag {
      font-size: 15px;
      line-height: 22px;
      padding-bottom: 10px;
      border-bottom: 1px solid $color-black;

      @include media-breakpoint-up(sm) {
        font-size: 18px;
        line-height: 24px;
      }

      @include media-breakpoint-up(md) {
        font-size: 18px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 26px;
        line-height: 35px;
      }
    }
  }

  &.header-link {
    text-align: left;

    .heading-tag {
      font-size: 10px;
      line-height: 17px;
      padding-bottom: 5px;

      @include media-breakpoint-up(sm) {
        font-size: 13px;
        line-height: 19px;
      }

      @include media-breakpoint-up(md) {
        font-size: 13px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 21px;
        line-height: 30px;
      }
    }
  }
}
</style>
