export default {
  currentHighestSignature_id: (state) => {
    return state.signatures.length
      ? Math.max.apply(
          Math,
          state.signatures.map((e) => e.id)
        )
      : 0;
  },
  currentHighestVariable_id: (state) => {
    return state.variables_configuration.length
      ? Math.max.apply(
          Math,
          state.variables_configuration.map((e) => e.id)
        )
      : 0;
  },
};
