<template>
  <component
    :is="dataTag"
    :id="dataId"
    :ref="dataRef"
    :aria-label="dataAriaLabel"
    :class="['CtaButtonCmp', { disabled: dataDisabled, loading: dataLoading }]"
    :role="dataTag === 'a' ? 'button' : null"
    :href="dataTag === 'a' ? dataUrl : null"
    :type="dataTag === 'button' ? dataType : null"
    :to="dataTag === 'router-link' ? dataTo : null"
    :disabled="dataDisabled"
    :target="dataBlank ? '_blank' : null"
    :style="componentStyles"
    v-on="$listeners"
  >
    <div v-if="hasBeforeIcon" class="icon before-icon">
      <slot name="before-icon" />
    </div>
    <span>
      <slot />
    </span>
    <div v-if="hasAfterIcon || dataLoading" class="icon after-icon">
      <!-- TODO [FE] replace loading effect -->
      <svg v-if="dataLoading">
        <use href="#icon-ellipsis-loader" fill="currentColor" />
      </svg>
      <slot v-show="!dataLoading" name="after-icon" />
    </div>
  </component>
</template>

<script>
export default {
  name: 'CtaButtonCmp',
  props: {
    dataId: {
      type: String,
      default: null,
    },
    dataRef: {
      type: String,
      default: null,
    },
    dataAriaLabel: {
      type: String,
      default: null,
    },
    dataTag: {
      type: String,
      default: 'a',
    },
    dataUrl: {
      type: String,
      default: null,
    },
    dataType: {
      type: String,
      default: 'button',
    },
    dataTo: {
      type: [String, Object],
      default: null,
    },
    dataDisabled: {
      type: Boolean,
      default: null,
    },
    dataBlank: {
      type: Boolean,
      default: null,
    },
    dataLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasBeforeIcon() {
      return !!(this.$slots['before-icon'] || [])[0];
    },
    hasAfterIcon() {
      return !!(this.$slots['after-icon'] || [])[0];
    },
    componentStyles() {
      return {
        backgroundColor: 'var(--brand-color)',
        borderColor: 'var(--brand-color)',
        color: 'var(--text-color-on-brand-color)',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.CtaButtonCmp {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  text-decoration: none !important;
  cursor: pointer;
  /* default appearance */
  font-size: 14px;
  font-weight: $fh-medium;
  background: $color-primary;
  color: $color-white;
  border-width: 1px;
  border-style: solid;
  box-shadow: none;

  &:disabled,
  &.disabled {
    opacity: 0.333;
    cursor: not-allowed;
  }

  &:not(:disabled):not(.disabled):active {
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    outline: none;
  }

  &:not(:disabled):not(.disabled):hover {
    color: $color-white;
  }

  .icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 22px;

    &.before-icon {
      margin-right: 10px;
    }

    &.after-icon {
      margin-left: 10px;
      margin-right: -54px;
    }

    svg {
      position: absolute;
      left: 0;
      width: 44px;
      height: 44px;
    }
  }

  span {
    text-align: left;
  }

  /* theme classes */

  /* transparent */
  &.transparent {
    background: transparent;
    border: 0;
  }

  /* rounded border */
  &.rounded-border {
    border-radius: 0.25em;
  }

  /* btn-block */
  &.btn-block {
    display: flex;
    width: 100%;
  }

  /* PSA button */
  &.psa-button {
    width: 100%;
    background: $color-secondary;
    padding: 20px 0;
    border: 0;
    font-size: 13px;
    font-weight: $fh-bold;

    @include media-breakpoint-up(lg) {
      font-size: 14px;
    }
  }

  &.big-button {
    padding: 30px 20px;
    width: 100%;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;

    span {
      text-align: center;
    }

    @include media-breakpoint-up(md) {
      padding: 40px 30px;
      font-size: 22px;
      line-height: 30px;
    }
  }
}
</style>
