import * as types from '../mutation_types';

export default {
  [types.UPDATE_WIZARD_DATA](state, payload) {
    if (payload.obj) {
      let position = null;
      const array = Object.keys(payload.obj);
      array.forEach((k, i) => {
        state[payload.key][k] = payload.obj[k];
        if (payload.key === 'renting_session_status' && payload.obj[k]) {
          position = i + 1;
        }
      });
      if (position !== null) {
        state.currentStep =
          position + 1 > array.length ? array.length : position + 1;
        state.disabledFrom = position + 2;
      }
    }
  },
  [types.UPDATE_CURRENT_STEP](state, payload) {
    state.currentStep = payload;
  },
  [types.UPDATE_DISABLED_FROM](state, payload) {
    state.disabledFrom = payload;
  },
  [types.UPDATE_OTP](state, payload) {
    state.otp = payload;
  },
  [types.UPDATE_PROVINCES](state, payload) {
    state.provincesList = payload;
  },
  [types.UPDATE_COUNTRIES](state, payload) {
    state.countriesList = payload;
  },
  [types.UPDATE_STEP_DATA](state, payload) {
    state[payload.step][payload.key] = payload.value;
  },
};
