import { render, staticRenderFns } from "./bootstrap-modal-cmp-new.vue?vue&type=template&id=597c09c2&scoped=true&"
import script from "./bootstrap-modal-cmp-new.vue?vue&type=script&lang=js&"
export * from "./bootstrap-modal-cmp-new.vue?vue&type=script&lang=js&"
import style0 from "./bootstrap-modal-cmp-new.vue?vue&type=style&index=0&id=597c09c2&prod&lang=scss&scoped=true&"
import style1 from "./bootstrap-modal-cmp-new.vue?vue&type=style&index=1&id=597c09c2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "597c09c2",
  null
  
)

export default component.exports