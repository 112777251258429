/* Import here all global assets */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Stylesheets
import '$scss/common.scss';

// Images
import '@images/logo-free2move.png';
import '@images/208-thumbnail.png';
import '@images/508-thumbnail.png';
import '@images/308-SW-black-slide-1.png';
import '@images/308-SW-black-slide-2.png';
import '@images/308-SW-blue-slide-1.png';
import '@images/308-SW-blue-slide-2.png';
import '@images/308-SW-green-slide-1.png';
import '@images/308-SW-green-slide-2.png';
