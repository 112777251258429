/* Import dependencies */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

/* Mutations, actions and getters */
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

/* Modules */
// Import modules here
import pdfConfig from './pdf-config';
import wizard from './wizard';

export default new Vuex.Store({
  state: {
    appReady: false,
    loading: [],
    unlockedView: null,
    //when we receive the logo from the customer we put in the component so we can use
    //this to know if we should make a fallback to stellantis
    hasSvgBrandLogo: false,
  },
  actions,
  mutations,
  getters,
  modules: {
    pdfConfig,
    wizard,
  },
});
