import * as types from '../mutation_types';
import axios from 'axios';

export default {
  readSensibleData({ commit, state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      dispatch('setLoading', true, { root: true });

      const api = payload.queryString
        ? `${payload.api.sensibleData}?otp=${payload.queryString}`
        : payload.api.sensibleData;

      const promises = [];
      promises.push(
        axios.get(api).then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach((k) => {
              commit(types.UPDATE_WIZARD_DATA, {
                key: k,
                obj: response.data[k],
              });
            });
          }
        })
      );
      promises.push(
        axios.get(payload.api.provinces).then((response) => {
          if (response.data) {
            commit(
              types.UPDATE_PROVINCES,
              response.data.map((e) => ({
                value: e.acronym,
                text: e.acronym,
              }))
            );
          }
        })
      );
      promises.push(
        axios.get(payload.api.countries).then((response) => {
          if (response.data) {
            commit(
              types.UPDATE_COUNTRIES,
              response.data.map((e) => {
                const key = Object.keys(e)[0];
                return {
                  value: key,
                  text: e[key],
                };
              })
            );
          }
        })
      );

      Promise.all(promises)
        .then(() => {
          resolve(state.currentStep);
          dispatch('setLoading', false, { root: true });
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
          reject(e);
          dispatch('setLoading', false, { root: true });
        });
    });
  },
  updateCurrentStep({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.UPDATE_CURRENT_STEP, payload);
        resolve();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        reject(e);
      }
    });
  },
  updateDisabledFrom({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.UPDATE_DISABLED_FROM, payload);
        resolve();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        reject(e);
      }
    });
  },
  updateOtp({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.UPDATE_OTP, payload);
        resolve();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        reject(e);
      }
    });
  },
  updateStepData({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        if (Array.isArray(payload)) {
          payload.forEach((el) => {
            commit(types.UPDATE_STEP_DATA, el);
          });
        } else {
          commit(types.UPDATE_STEP_DATA, payload);
        }
        resolve();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        reject(e);
      }
    });
  },
};
