<template>
  <div class="PageAccordions">
    <div class="container">
      <div class="row">
        <div class="col">
          <accordion-extended autoclose :items="accordions">
            <template v-for="accordion in accordions" v-slot:[accordion.slot]>
              <div :key="accordion.id" v-html="accordion.text"></div>
            </template>
          </accordion-extended>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccordionExtended from '@components/accordion-extended';

export default {
  name: 'PageAccordions',
  components: {
    AccordionExtended,
  },
  computed: {
    accordions() {
      const accordions =
        this.$context.accordionConfiguration.accordions.length > 0
          ? this.$context.accordionConfiguration.accordions
          : [];

      return accordions.map((a) => {
        return {
          ...a,
          slot: `content-${a.id}`,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
