<template>
  <header
    :class="[
      'HeaderCmp',
      {
        branded: !!currentBrand && svgLogoId !== 'logo-stellantis',
        isVisorVariant,
      },
    ]"
    :style="componentStyles"
  >
    <img
      v-if="isVisorVariant"
      class="visorImage"
      :src="headerImage"
      :alt="`${$context.cmsEntry.homepage_header_title} - ${$context.cmsEntry.homepage_header_subtitle}`"
    />
    <cta-button-cmp
      v-if="isVisorVariant"
      data-tag="a"
      class="scrollToInfoCta psa-button big-button py-5"
      :data-url="`#${anchorHref}`"
    >
      Scopri di più
    </cta-button-cmp>
    <div class="headerWrapper">
      <div
        :class="[
          {
            'container': !isVisorVariant,
            'container-fluid': isVisorVariant,
          },
        ]"
      >
        <div class="row">
          <div class="col">
            <i
              v-if="$currentStep === 'xs' || $currentStep === 'sm'"
              :class="[
                'brandLine',
                {
                  visible: hasBrandLine && !hideBrandAndLine,
                },
              ]"
            ></i>
            <a v-if="!hideBrandAndLine" :href="homepageUrl" class="logo">
              <svg v-if="svgLogoId !== 'logo-stellantis'">
                <use :href="`#${svgLogoId}`" fill="currentColor" />
              </svg>
              <img
                v-else
                :src="require(`../../img/logo_sr_big_alt.png`)"
                alt="logo-stellantis"
              />
            </a>
            <i
              :class="[
                'brandLine',
                { visible: hasBrandLine && !hideBrandAndLine },
              ]"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
/*
brands
current-brand
*/
import CtaButtonCmp from '@components/cta-button-cmp';
export default {
  name: 'HeaderCmp',
  components: { CtaButtonCmp },
  props: ['dataVariant'],
  computed: {
    headerImage() {
      /*
        828x792
        1536x640
        1920x800
      */
      switch (this.$currentStep) {
        case 'md':
          return (
            this.$context.cmsEntry.homepage_visor_image_m ||
            'https://via.placeholder.com/1536x640.png?text=MD'
          ); //;
        case 'lg':
        case 'xl':
          return (
            this.$context.cmsEntry.homepage_visor_image_l ||
            'https://via.placeholder.com/1920x800.png?text=LG'
          ); //;
        default:
          //sm
          return (
            this.$context.cmsEntry.homepage_visor_image_s ||
            'https://via.placeholder.com/828x792.png?text=SM'
          ); //;
      }
    },
    isVisorVariant() {
      return this.dataVariant === 'visor';
    },
    componentStyles() {
      const styles = {
        color: this.currentBrandData?.headerForegroundColor || '#fff',
      };

      if (!this.isVisorVariant) {
        styles.backgroundColor =
          this.currentBrandData?.headerBackgroundColor || '#243782';
      }

      return styles;
    },
    currentBrand() {
      return this.$context.currentBrand;
    },
    hasBrandLine() {
      return this.currentBrand === 'peugeot';
    },
    brands() {
      return this.$context.brands;
    },
    currentBrandData() {
      return this.$context.brands.find((b) => b.id === this.currentBrand);
    },
    isEmployeeSite() {
      return this.$context.isEmployeeSite;
    },
    homepageUrl() {
      if (!this.isEmployeeSite && this.currentBrandData?.homepageUrl) {
        return this.currentBrandData?.homepageUrl;
      }
      return '/';
    },
    svgLogoId() {
      if (this.$store.state.hasSvgBrandLogo) {
        return this.currentBrandData?.svgLogoId;
      } else {
        return 'logo-stellantis';
      }
    },
    isMobileOrTablet() {
      return this.$viewportWidth <= 992;
    },
    hideBrandAndLine() {
      return this.$context.cmsEntry.hide_brand;
    },
    modalContent() {
      return this.$context.cmsEntry.header_modal_text;
    },
    headerItems() {
      return this.$context.cmsEntry.header_items;
    },
    anchorHref() {
      return getComputedStyle(document.documentElement)
        .getPropertyValue('--footer-info-id')
        .trim();
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.HeaderCmp {
  --logo-size-half: 45px;
  --logo-size: 90px;
  @include media-breakpoint-up(md) {
    --logo-size: 90px;
    &.isVisorVariant {
      --logo-size: 110px;
    }
  }
  @include media-breakpoint-up(lg) {
    --logo-size: 110px;
    &.isVisorVariant {
      --logo-size: 150px;
    }
  }

  margin: 0 auto;
  .visorImage {
    width: 100%;
    display: block;
  }

  &.isVisorVariant {
    max-width: 1920px;
    position: relative;
    min-height: 358px;
    padding: 0 0 20px;
    @include media-breakpoint-up(sm) {
      min-height: 412px;
    }
    @include media-breakpoint-up(md) {
      padding: 0;
      min-height: 305px;
    }
    @include media-breakpoint-up(lg) {
      min-height: 426px;
    }
    .headerWrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 100;
    }
    .scrollToInfoCta {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 100;
      width: 100%;
      @include media-breakpoint-up(md) {
        width: auto;
        bottom: 30px;
        right: 30px;
      }
    }
  }

  .logo {
    display: inline-block;
    vertical-align: middle;
    svg {
      /*width: 100%;
      height: 100%;*/
      // @include media-breakpoint-up(md) {
      //   width: auto;
      //   height: auto;
      // }
    }
  }

  //  breakpoints: { xs: 0, sm: 380, md: 698, lg: 1024, xl: 1314 },

  &.branded {
    .logo {
      width: var(--logo-size);
      height: var(--logo-size);
      padding: 5px 0;
      svg {
        width: 100%;
        height: 100%;
      }

      @include media-breakpoint-up(md) {
        padding: 15px 0;
      }
    }
    .brandLine {
      vertical-align: middle;
      width: calc(50% - var(--logo-size-half));

      &.visible {
        border: 1px solid #fff;
      }

      display: inline-block;
      @include media-breakpoint-up(md) {
        width: calc(100% - var(--logo-size));
      }
    }
  }
}

/*
.HeaderCmp {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  @include responsive-properties(('height'), $header-height);
  //background: $color-primary;
  //color: $color-white;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 5px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAHBAMAAADzDtBxAAAAD1BMVEUAAAAAAAAAAAAAAAAAAABPDueNAAAABXRSTlMUCS0gBIh/TXEAAAAaSURBVAjXYxCEAgY4UIICBmMogMsgFLtAAQCNSwXZKOdPxgAAAABJRU5ErkJggg==);
    background-position: left 0 top -2px;
    background-repeat: repeat-x;
  }

  .logo {
    display: inline-block;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .logo {
    width: 208px;
    height: 69px;
    color: inherit;

    @include media-breakpoint-up(lg) {
      width: 286px;
      height: 95px;
    }
  }

  &.branded {
    .logo {
      width: 90px;
      height: 90px;
      padding: 5px 0;

      @include media-breakpoint-up(lg) {
        width: 110px;
        height: 110px;
        padding: 7.5px 0;
      }
    }
  }

  .HeadingCmp {
    display: none;
    font-size: 20px;
    text-align: left;
    padding-left: 10px;

    @include media-breakpoint-up(md) {
      display: inline-block;
    }

    ::v-deep .subtitle {
      font-size: 16px;
      font-weight: $fh-regular;
      margin-top: 5px !important;
    }
  }

  .headerModal {
    cursor: pointer;

    .mobile-modal-btn {
      display: flex;
      align-items: center;

      .label {
        text-align: right;
        font-size: 8px;
        padding-right: 12px;
        font-weight: $fh-bold;

        @include media-breakpoint-up(md) {
          font-size: 10px;
        }
      }
    }

    .iconsList {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 40px;

      @include media-breakpoint-up(xl) {
        gap: 96px;
      }

      .iconItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .icon {
          max-width: 86px;
        }

        .label {
          font-size: 8px;
          font-weight: $fh-bold;
          padding-top: 10px;
          color: #cbcbcb;
        }
      }
    }
  }

  ::v-deep {
    .modal-content {
      color: #000;
      font-size: 13px;
      line-height: 20px;

      img {
        width: 100%;
        @include media-breakpoint-up(md) {
          width: auto;
        }
      }

      ul,
      ol {
        list-style: auto;
        margin: 10px 0 0 24px;
      }

      ul {
        li {
          list-style-type: disc;
        }
      }
    }
  }
}*/
</style>
