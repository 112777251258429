import * as Sentry from '@sentry/vue';
import axios from 'axios';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { colorLog } from '@helpers/utils.js';
import { ignoreErrors, beforeSend, denyUrls } from '@helpers/sentry_utils';

export const canSenty =
  !window.location.hostname.includes('localhost') &&
  !window.location.hostname.includes('develop');

export default function init(Vue, sentryDsn, environment) {
  Sentry.init({
    Vue: Vue,
    dsn: sentryDsn,
    logErrors: true,
    environment: `[FE] - ${environment}`,
    attachStacktrace: true,
    integrations: [
      new TracingIntegrations.BrowserTracing({
        //tracingOrigins: ["localhost", "my-site-url.com", /^\//],
        // ... other options
      }),
    ],
    //release to configure in automation if we have a release version in the project
    ignoreErrors: ignoreErrors,
    denyUrls: denyUrls,
    beforeSend: beforeSend,
  });
  // console.log('Sentry init with ', window.sentryDsn);

  axios.interceptors.request.use(
    function (config) {
      return config;
    },
    function (err) {
      if (err === void 0) {
        //unhandled reject
        return;
      }

      const properties = Object.getOwnPropertyNames(err),
        extra = {};

      properties.forEach((p) => {
        const prop = Object.getOwnPropertyDescriptor(err, p);
        if (prop && prop.value) {
          extra[p] = prop.value;
        }
      });

      if (canSenty) {
        Sentry.withScope(function (scope) {
          scope.setLevel('error');
          Sentry.setTags({
            axios_source: 'request',
            logger: 'Axios',
            //...extra
          });
          Sentry.captureMessage(`[Axios] -> [Request]`, {
            level: 'error',
            extra,
          });
        });
        colorLog({
          label: 'axios error',
          message: `Our team has been informed`,
          themeName: 'red',
        });
      }

      // eslint-disable-next-line
      console.error(err);
      return Promise.reject(err);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (err) {
      if (err === void 0) {
        //unhandled reject
        return;
      }

      const properties = Object.getOwnPropertyNames(err),
        extra = {};

      properties.forEach((p) => {
        const prop = Object.getOwnPropertyDescriptor(err, p);
        if (prop && prop.value) {
          extra[p] = prop.value;
        }
      });

      if (
        extra &&
        extra.config &&
        extra.response &&
        extra.config.method &&
        extra.config.url &&
        extra.response.status
      ) {
        const title = `[ ${extra.config.method.toUpperCase()} ] on [ ${
          extra.config.url
        } ] returned [ ${extra.response.status} ]`;
        if (canSenty) {
          Sentry.withScope(function (scope) {
            scope.setLevel('warning');
            Sentry.setTags({
              axios_source: 'response',
              logger: 'Axios',
              requestMethod: extra.config.method,
              requestUrl: extra.config.url,
              responseSttatus: extra.response.status,
            });
            Sentry.captureMessage(title, {
              level: 'warning',
              extra: extra,
            });
          });
        }
      } else {
        const title = `[Axios] -> [Request] -> ${extra.message}`;
        if (canSenty) {
          Sentry.withScope(function (scope) {
            scope.setLevel('error');
            Sentry.setTags({
              axios_source: 'response',
              logger: 'Axios',
            });
            Sentry.captureMessage(title, {
              level: 'error',
              extra: extra,
            });
          });
          colorLog({
            label: 'axios error',
            message: `Our team has been informed`,
            themeName: 'red',
          });
        }

        // eslint-disable-next-line
        console.error(err);
      }

      return Promise.reject(err);
    }
  );

  Vue.config.errorHandler = function VueErrorHandler(err, vm, info) {
    if (err === void 0) {
      //unhandled reject
      return;
    }

    const properties = Object.getOwnPropertyNames(err),
      extra = {};

    properties.forEach((p) => {
      const prop = Object.getOwnPropertyDescriptor(err, p);

      if (prop && prop.value) {
        if (p !== 'stack') {
          extra[p] = prop.value;
        }
      }
    });

    extra['EXTRA-vue-options-name'] = vm?.$options?.name;
    extra['EXTRA-vue-info'] = info;

    if (properties.includes('message')) {
      const message = Object.getOwnPropertyDescriptor(err, 'message');
      const stack = Object.getOwnPropertyDescriptor(err, 'stack');
      if (canSenty) {
        Sentry.withScope(function (scope) {
          scope.setLevel('error');

          Sentry.setTags({
            logger: 'javascript',
            ...extra,
          });

          Sentry.captureMessage(
            `[${vm?.$options?.name || 'App'}] -> [${info}] -> ${message.value}`,
            {
              level: 'error',
              extra: {
                stack: stack ? stack.value : '---',
              },
              //extra: extra
            }
          );
        });
        colorLog({
          label: 'javascript error',
          message: `Our team has been informed`,
          themeName: 'red',
        });
      }
      // eslint-disable-next-line
      console.error(err);
    } else {
      if (canSenty) {
        Sentry.withScope(function (scope) {
          scope.setLevel('error');
          Sentry.setTags({
            logger: 'javascript',
            source: 'unknown',
          });
          Sentry.captureMessage('Generic untracked error', {
            level: 'error',
            extra: extra,
          });
        });
      }
      // eslint-disable-next-line
      console.error('[LOGGER VUE GLOBAL ERROR HANDLER] Ops!');
      // eslint-disable-next-line
      console.error(err);
    }
  };

  Vue.config.catchHandler = function VueErrorHandler(err, vm, info) {
    if (err === void 0) {
      //unhandled reject
      return;
    }

    const properties = Object.getOwnPropertyNames(err),
      extra = {};

    properties.forEach((p) => {
      const prop = Object.getOwnPropertyDescriptor(err, p);
      if (prop && prop.value) {
        if (p !== 'stack') {
          extra[p] = prop.value;
        }
      }
    });

    extra['EXTRA-vue-options-name'] = vm?.$options?.name;
    extra['EXTRA-vue-info'] = info;

    if (properties.includes('message')) {
      const message = Object.getOwnPropertyDescriptor(err, 'message');
      const stack = Object.getOwnPropertyDescriptor(err, 'stack');
      if (message.value.indexOf('failed with status code') === -1) {
        if (canSenty) {
          Sentry.withScope(function (scope) {
            scope.setLevel('error');

            Sentry.setTags({
              logger: 'javascript',
              ...extra,
            });

            Sentry.captureMessage(
              `[${vm?.$options?.name || 'App'}] -> [${info}] -> ${
                message.value
              }`,
              {
                level: 'error',
                extra: {
                  stack: stack ? stack.value : '---',
                },
              }
            );
          });

          colorLog({
            label: 'javascript error',
            message: `Our team has been informed`,
            themeName: 'red',
          });
        }
        // eslint-disable-next-line
        console.error(err);
      } else {
        //already managed in axios
      }
    } else {
      //you can easly comment this
      if (properties.includes('title')) {
        const title = Object.getOwnPropertyDescriptor(err, 'title');
        if (canSenty) {
          Sentry.withScope(function (scope) {
            scope.setLevel('info');

            Sentry.setTags({
              logger: 'Promises',
              ...extra,
            });

            Sentry.captureMessage(`Promise rejected - ${title.value}`, {
              level: 'error',
            });
          });
        }
      } else {
        if (canSenty) {
          Sentry.withScope(function (scope) {
            scope.setLevel('warning');

            Sentry.setTags({
              logger: 'Promises',
              ...extra,
            });

            Sentry.captureMessage(`Promise rejected`, {
              level: 'error',
            });
          });
        }
      }
    }
  };

  (function (Promise) {
    var originalCatch = Promise.prototype.catch;
    Promise.prototype.catch = function (onRejected) {
      return originalCatch.call(this, function (value) {
        Vue.config.catchHandler.call(null, value, this);
        return onRejected(value);
      });
    };
  })(Promise);
}
