export const setCookie = function (cname, cvalue, exdays, path, domain) {
  let date = new Date();

  date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);

  const expires = 'expires=' + date.toUTCString();
  document.cookie = `${cname}=${cvalue}; ${expires}; path=${path}; Domain=${domain}`;
};

export const getCookie = function (cname) {
  const name = cname + '=';
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1);
    if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
  }

  return null;
};

export const checkCookie = function (name) {
  const ck = getCookie(name);

  return ck ? !0 : !!0;
};
