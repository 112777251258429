<template>
  <div class="HomePage">
    <header-cmp :data-variant="$context.cmsEntry.homepage_variant" />
    <main class="main-content">
      <router-view class="py-11" />
      <additional-details class="pb-11" />
      <page-accordions class="pb-11" />
    </main>
    <footer-cmp />
    <app-loader-cmp />
    <svg-icons-cmp />
  </div>
</template>

<script>
import AppLoaderCmp from '@components/app-loader-cmp';
import FooterCmp from '@components/footer-cmp';
import HeaderCmp from '@components/header-cmp';
import SvgIconsCmp from '@components/svg-icons-cmp';
import AdditionalDetails from '@components/additional-details';
import PageAccordions from '@components/page-accordions';

export default {
  name: 'HomePage',
  components: {
    AppLoaderCmp,
    FooterCmp,
    HeaderCmp,
    SvgIconsCmp,
    AdditionalDetails,
    PageAccordions,
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch('setLoading', false);
      this.$store.dispatch('setAppReady', true);
    });
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.HomePage {
  .main-content {
    min-height: calc(
      100vh - #{fetch($header-height, xs)} - #{fetch($footer-height, xs)}
    );

    @include media-breakpoint-up(md) {
      min-height: calc(
        100vh - #{fetch($header-height, md)} - #{fetch($footer-height, md)}
      );
    }

    @include media-breakpoint-up(lg) {
      min-height: calc(
        100vh - #{fetch($header-height, lg)} - #{fetch($footer-height, lg)}
      );
    }
  }
}
</style>
