<template>
  <label :class="['FilterCheckboxCmp', { checked, disabled }]">
    <input
      type="checkbox"
      class="input"
      :name="name"
      :value="checkedValue"
      :checked="checked"
      :disabled="disabled"
      @change="onChange"
    />
    <span class="label">
      <slot />
      <transition name="scale">
        <svg v-if="checked" class="check">
          <use href="#icon-check" />
        </svg>
      </transition>
    </span>
  </label>
</template>

<script>
export default {
  name: 'FilterCheckboxCmp',
  props: {
    value: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    checkedValue: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  computed: {
    checked() {
      return this.value.includes(this.checkedValue);
    },
  },
  methods: {
    onChange(e) {
      if (e.target.checked) {
        this.$emit('input', [...this.value, this.checkedValue]);
      } else {
        this.$emit('input', [
          ...this.value.filter((i) => i !== this.checkedValue),
        ]);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.FilterCheckboxCmp {
  display: block;
  position: relative;
  color: $color-almost-black;
  transition: color 0.3s ease-in-out;
  --checkbox-main-color: #{$color-primary};

  &.disabled {
    --checkbox-main-color: #{$color-dark-grey};
  }

  &.checked {
    color: $color-white;
  }
}

.input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.label {
  position: relative;
  display: block;
  padding: 16px 40px 16px 16px;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  border: 1px solid $color-grey;
  background: $color-white;
  transition: border 0.3s ease-in-out, background-color 0.3s ease-in-out;

  &:hover {
    border-color: $color-light-grey;
  }

  .checked & {
    background-color: var(--checkbox-main-color);
    border-color: var(--checkbox-main-color);
  }

  .disabled & {
    cursor: not-allowed;
    border-color: $color-grey;
    background: $color-grey;
    color: $color-dark-grey;
  }
}

.check {
  display: block;
  font-size: 16px;
  width: 1em;
  height: 1em;
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -0.5em;
  color: $color-white;
}

.scale-enter-active,
.scale-leave-active {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.scale-enter,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.5);
}
</style>
